/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FinishedComparisonsSubscriptionVariables = {||};
export type FinishedComparisonsSubscriptionResponse = {|
  +finishedComparisonsSubscription: ?{|
    +id: ?string,
    +status: ?string,
    +comparisonId: ?string,
    +comparisonsDone: ?number,
    +comparisonDetails: ?$ReadOnlyArray<?{|
      +id: ?string,
      +diffPngsIds: ?$ReadOnlyArray<?string>,
      +status: ?string,
      +referentPdfId: ?string,
      +actualPdfId: ?string,
      +fileName: ?string,
    |}>,
  |}
|};
export type FinishedComparisonsSubscription = {|
  variables: FinishedComparisonsSubscriptionVariables,
  response: FinishedComparisonsSubscriptionResponse,
|};
*/


/*
subscription FinishedComparisonsSubscription {
  finishedComparisonsSubscription {
    id
    status
    comparisonId
    comparisonsDone
    comparisonDetails {
      id
      diffPngsIds
      status
      referentPdfId
      actualPdfId
      fileName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ComparisonGeneratePdfResult",
    "kind": "LinkedField",
    "name": "finishedComparisonsSubscription",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comparisonId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comparisonsDone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ComparisonDetails",
        "kind": "LinkedField",
        "name": "comparisonDetails",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "diffPngsIds",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referentPdfId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualPdfId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FinishedComparisonsSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FinishedComparisonsSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7eada7ac09f9e5dc0f48b65dab012f52",
    "id": null,
    "metadata": {},
    "name": "FinishedComparisonsSubscription",
    "operationKind": "subscription",
    "text": "subscription FinishedComparisonsSubscription {\n  finishedComparisonsSubscription {\n    id\n    status\n    comparisonId\n    comparisonsDone\n    comparisonDetails {\n      id\n      diffPngsIds\n      status\n      referentPdfId\n      actualPdfId\n      fileName\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b9d3d033e0f4ef44d65060fe1c18848';

module.exports = node;
