import { cond, equals, gt, length, prop, sum } from 'ramda';
import { useEffect, useState } from 'react';
import { requestSubscription, useRelayEnvironment } from 'react-relay';
import GenerateComparePdfMutation from '../_graphql/mutations/documents/GenerateComparePdfMutation';
import { FinishedComparisonsSubscriptionQuery } from '../_graphql/subscriptions/comparePDF/FinishedComparisonsSubscription';

const useCompare = (setRefetch) => {
  const [loadingOnCompareAll, setLoadingOnCompareAll] = useState(false);
  const [loadingOnCompareOnRow, setLoadingOnCompareOnRow] = useState(false);
  const [loadingOnCompareOnSelected, setLoadingOnCompareOnSelected] = useState(false);
  const [nbComparisons, setNbComparisons] = useState(0);
  const [statusComparisons, setStatusComparisons] = useState({
    nbComparisonsDone: 0,
    passed: 0,
    failed: 0,
    error: 0
  });
  const [progress, setProgress] = useState(0);
  const [openModalVisible, setIsModalVisible] = useState(false);
  const environment = useRelayEnvironment();

  useEffect(() => {
    const nbComparisonsDone = prop('nbComparisonsDone', statusComparisons);
    const totalComparisonsDone = sum([prop('passed', statusComparisons), prop('failed', statusComparisons), prop('error', statusComparisons)]);

    if (gt(nbComparisons, 0)) {
      if (equals(totalComparisonsDone, nbComparisons)) {
        setProgress(100);
      } else {
        setProgress((nbComparisonsDone / nbComparisons) * 100);
      }
    } else {
      setProgress(0);
    }
  }, [statusComparisons, nbComparisons]);

  useEffect(() => {
    if (!openModalVisible) {
      setProgress(0);
      setNbComparisons(0);
      setStatusComparisons({
        nbComparisonsDone: 0,
        passed: 0,
        failed: 0,
        error: 0
      });
    }
  }, [openModalVisible]);

  useEffect(() => {
    const finishedComparisons = requestSubscription(environment, {
      subscription: FinishedComparisonsSubscriptionQuery,
      variables: {},
      onNext: (response) => {
        if (response.finishedComparisonsSubscription) {
          const { comparisonsDone, status } = response.finishedComparisonsSubscription;
          setStatusComparisons((prevStatus) => ({
            nbComparisonsDone: comparisonsDone,
            passed: prevStatus.passed + (status === 'passed' ? 1 : 0),
            failed: prevStatus.failed + (status === 'failed' ? 1 : 0),
            error: prevStatus.error + (status === 'error' ? 1 : 0)
          }));
        }
      },
      onError: (error) => {
        console.error('Subscription error:', error);
      }
    });

    return () => finishedComparisons.dispose();
  }, [environment]);

  const updateLoading = (type, bool) => cond([
    [equals('all'), () => setLoadingOnCompareAll(bool)],
    [equals('row'), () => setLoadingOnCompareOnRow(bool)],
    [equals('selected'), () => setLoadingOnCompareOnSelected(bool)]
  ])(type);

  const onCompare = (type, ids) => {
    setIsModalVisible(true);
    updateLoading(type, true);
    setNbComparisons(length(ids));

    GenerateComparePdfMutation({ ids }, () => updateLoading(type, false));
  };

  return {
    loadingOnCompareAll,
    loadingOnCompareOnRow,
    loadingOnCompareOnSelected,
    nbComparisons,
    progress,
    openModalVisible,
    setIsModalVisible,
    statusComparisons,
    onCompare
  };
};

export default useCompare;
